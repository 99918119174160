import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href?: string;
  src: string;
  width: string;
  height: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src, width, height }: SocialLinkProps) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" key={src}>
        <SvgIcon src={src} width={width} height={height} />
      </a>
    );
  };

  return (
    <>
      <Extra>
        <Row
          align="middle"
          justify="space-evenly"
          style={{ paddingTop: "3rem" }}
        >
          <Col lg={1} md={6} sm={6} xs={3}>
            <ScrollToTop>
              <SocialLink src="dogandcat.svg" width="50px" height="50px" />
            </ScrollToTop>
          </Col>
          <Col lg={1} md={6} sm={6} xs={3}>
            <SocialLink
              href="https://instagram.com/miamipetservices"
              src="instagram.svg"
              width="50px"
              height="50px"
            />
          </Col>
          <Col lg={1} md={6} sm={6} xs={3}>
            <SocialLink
              href="https://www.facebook.com/MiamiPetServices1/"
              src="facebook.svg"
              width="50px"
              height="50px"
            />
          </Col>
        </Row>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
